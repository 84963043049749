import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { SectionWrapper, ImgHolderStyles } from './ImgHolder.styles';

const ImgHolder = ({ imgSrc, linkUrl, showBtn }) => (
  <SectionWrapper>
    <a target="_blank" href={linkUrl} rel="noreferrer noopener">
      <ImgHolderStyles target="_blank" src={imgSrc} />
    </a>
    {showBtn && (
      <Button
        target="_blank"
        href={linkUrl}
        type="primary"
        className="resource-button"
        size="large"
      >
        Watch Now
      </Button>
    )}
  </SectionWrapper>
);

ImgHolder.defaultProps = { showBtn: true };

ImgHolder.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  showBtn: PropTypes.bool,
};

export default ImgHolder;
