import styled from 'styled-components';
import fromTheme from '../../helpers/fromTheme';

const ImgHolderStyles = styled.img`
  max-width: 100%;
  padding: 50px 0 0;
  @media (max-width: 991px) {
    padding: 0;
    margin: 20px auto 0;
  }
`;

const SectionWrapper = styled.div`
  text-align: center;
  .ant-btn.resource-button {
    max-width: 400px;
    margin-top: 20px;
    border: 0;
    border-radius: 5px;
    font-size: 18px;
    background-color: ${(props) => fromTheme(props, 'colors', 'primary')};
    &:hover {
      background-color: ${(props) => fromTheme(props, 'colors', 'primaryDarker')};
    }
  }
  @media all and (max-width: 786px) {
    .ant-btn.resource-button {
      font-size: 16px;
    }
  }
  @media all and (max-width: 576px) {
    .ant-btn.resource-button {
      font-size: 14px;
    }
  }
`;

export { SectionWrapper, ImgHolderStyles };
